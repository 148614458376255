import axios from 'axios';
import { AvailablePaymentMethod } from 'types/AvailablePaymentMethod';
import { WebPayment } from 'types/WebPayment';
import {
  MakePaymentByTokenRequestBody,
  MakePaymentRequestBody,
} from 'types/MakePaymentRequestBody';
import { QrPayment } from 'types/QrPayment';
import {
  CheckQrPaymentStatusRequestBody,
  CheckQrPaymentStatusResponse,
} from 'types/CheckQrPaymentStatus';
import { CheckWebPaymentStatusResponse } from 'types/CheckWebPaymentStatus';
import * as qs from 'query-string';
import { HotelLogo } from 'types/HotelLogo';
import { PreArrivalReservation } from 'types/PrearrivalRecord';
import { Theme } from '@material-ui/core';
const { REACT_APP_API_URL } = process.env;

const qsObject: any = qs.parse(window.location.search);
const apiAxios = axios.create({
  headers: {
    authorization:
      'Bearer ' +
      (localStorage.getItem('token') !== 'undefined' &&
      localStorage.getItem('token') !== undefined &&
      localStorage.getItem('token') !== null
        ? localStorage.getItem('token')
        : qsObject.token),
  },
});

export const getConfirmationBooking = async (id: string): Promise<any> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}confirmations/records/${id}`)
    .then((response) => response.data);
};

export const getPrearrivalBooking = async (
  id: string
): Promise<PreArrivalReservation> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}prearrivals/records/${id}`)
    .then((response) => response.data);
};

export const getAvailablePaymentMethods = async (
  bookingNumber?: string
): Promise<AvailablePaymentMethod[]> => {
  return apiAxios
    .get(
      `${REACT_APP_API_URL}payments/availablemethods` +
        (bookingNumber ? '?bookingNumber=' + bookingNumber : '')
    )
    .then((response) => response.data);
};

export const makeWebPayment = async (
  postBody: MakePaymentRequestBody
): Promise<WebPayment> => {
  return await apiAxios
    .post(`${REACT_APP_API_URL}payments`, postBody)
    .then((response) => response.data);
};

export const makeQrPayment = async (
  postBody: MakePaymentRequestBody
): Promise<QrPayment> => {
  return await apiAxios
    .post(`${REACT_APP_API_URL}payments`, postBody)
    .then((response) => response.data);
};

export const checkQrPaymentStatus = async (
  postBody: CheckQrPaymentStatusRequestBody
): Promise<CheckQrPaymentStatusResponse> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}payments/status?${qs.stringify(postBody as any)}`)
    .then((response) => response.data);
};

export const checkWebPaymentStatus = async (
  postBody: CheckQrPaymentStatusRequestBody
): Promise<CheckWebPaymentStatusResponse> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}payments/status?${qs.stringify(postBody as any)}`)
    .then((response) => response.data);
};

export const payPlanetDepositByToken = async (
  postBody: MakePaymentByTokenRequestBody
) => {
  return apiAxios
    .post(`${REACT_APP_API_URL}payments/planet/withtoken`, postBody)
    .then((response) => response.data);
};

export const getHotelConfigById = async (jwt: string): Promise<any> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}configurations/hotel/prearrivals`)
    .then((response) => response.data);
};

export const getAuthedHotelLogo = async (): Promise<HotelLogo> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}hotels/authed/logo`)
    .then((response) => response.data);
};

export const getHotelTheme = async (): Promise<Theme> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}themes/theme`)
    .then((response) => response.data);
};
