import { LanguageStrings } from './base-strings';

export const cn: LanguageStrings = {
  /** Routes */
  'routes.review': '/',
  'routes.registration': '/registration',
  'routes.confirm': '/confirm',
  'routes.checkinpayment': '/checkinpayment',
  'routes.confirmpayment': '/confirmpayment',
  'routes.hdfForm': '/hdf-form',

  /** Function Name */
  'review.menu.name': '检阅',
  'hdfForm.menu.name': '健康申報',
  'registration.menu.name': '登记',
  'confirm.menu.name': '确认',
  'error.menu.name': '错误',

  /** Page content */
  'home.title': '主页',
  'home.content': '欢迎进入我的网页',

  'summary.title': '总结',
  'summary.content': '详情可以浏覧以下连结 {medium} 以及 {devto}.',

  'loading.title': '载入中...',
  'loading.content': '正在载入你的预订详细信息，请稍等',

  'error.title': '错误',
  'error.content': '发生错误，请稍𢓭再试。',
  'error.required': '必需塡写',
  'error.dateOfBirth.day': '日期无效',
  'error.message2': '发生系统错误，请与系统管理员联系以获取详细信息。',
  'error.message.aleadySubmit': '您已经登记了所需资料，谢谢！',
  'error.message.notFound': '找不到资料！',
  'error.message.urlInvalid': '连结已失效！',
  'error.message.flightNumber': '航班编号例如CX231',
  'error.message.flightArrivalTime': '请输入有效时间',
  'error.message.preference': '请选择您的偏好',
  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',

  /** Payment T&C */
  'payment.t&c':
    '本人同意不会免除对此帐单的责任，并同意个人承担这些费用的全额。',

  /** Choice Befor Pay Room Rate */
  'choice.roomrateTitleOptional':
    '为了缩短抵达酒店时办理入住时间，您是否要现在支付房间费用？',
  'choice.roomrateTitleCompulsory':
    '为了令您有更快捷的入住登记体验，请您现在支付房间费用。',
  'choice.roomrate.no': '否，我到达酒店时才支付房间费用。',
  'choice.roomrate.title': '房间费用明细',

  /** Choice */
  'choice.depositTitleOptional':
    '为了缩短抵达酒店时办理入住时间，您是否要现在支付定金？',
  'choice.depositTitleCompulsory':
    '为了缩短抵达酒店时办理入住时间，请您现在支付定金。',
  'choice.yes': '是',
  'choice.no': '否，我到达酒店时才支付定金。',
  'choice.deposit.title': '押金明細',
  'choice.deposit': '押金',
  'choice.roomrate': '房价',
  'choice.total': '合共',
  'choice.warning': '你必需同意以上条款才能继续。',
  'choice.askSaveCreditCard':
    '本人授权酒店在信用卡中扣除商品及服务相关的费用。本人同意商家可能会保存个人信息以供日后付款。本人亦明白这项条款可因应要求随时撤销。',

  /** CheckInPayment */
  'checkinpayment.title': '请支付房价',
  'checkinpayment.ok': 'OK',
  'checkinpayment.selectpaymentmethod': '请选择付款方式',
  'checkinpayment.useSavedCard': '使用已儲存信用卡支付',
  'checkinpayment.success': '付款成功',
  'checkinpayment.merchantRef': '交易编号：',
  'checkinpayment.mini.success':
    'The payment for your reservation is Successfully Done.',
  'checkinpayment.mini.success2':
    'We look forward to welcoming you and remain committed to making your stay a smooth and memorable experience.',
  'checkinpayment.mini.success3': 'See You Soon!',
  'checkinpayment.error': '交易失败，请重新付款',
  'checkinpayment.bankError': '交易被拒绝，请联系您的银行',
  'checkinpayment.invoice': '我们会把发票传送到你的电邮',
  'checkinpayment.continue': '继续',
  'checkinpayment.pleasescanqrcode': '请用APP扫描二维码',
  'checkinpayment.payInNewTab': '请在新分页完成付款',
  'checkinpayment.payInNewTabNotOpened':
    '如果新分页没有自动打开，请按下面的按钮开始',
  'checkinpayment.startPayment': '开始付款',

  /** Review */
  'review.title': '请查看您的预订详细信息，然后单击“确认”按钮',
  'review.confirm.no': '确认号码',
  'review.guest.name': '宾客姓名',
  'review.checkin.date': '入住和退房日期',
  'review.reserved.room.type': '预留房间类型',
  'review.total.room.rate': '房价总费用',
  'review.preferences': '优先',
  'review.note':
    '请核对您的预订姓名与您的身份证件/护照相同。如您有任何疑问，请发送电邮致resvn@royalplaza.com.hk与我们联系。',
  'review.video.note': '在进行预先登记前，请点击 {play} 按钮观看介绍{video}。',
  'review.video.play': '播放',
  'review.video.video': '视频',
  'review.room.type.RSK': '高级客房',
  'review.room.type.RST': '高级客房',
  'review.room.type.RDK': '豪华客房',
  'review.room.type.RDT': '豪华客房',
  'review.room.type.RDH': '豪华客房',
  'review.room.type.RPK': '尊贵客房',
  'review.room.type.RPT': '尊贵客房',
  'review.room.type.RPH': '尊贵客房',
  'review.room.type.CDK': '行政豪华客房',
  'review.room.type.CDT': '行政豪华客房',
  'review.room.type.CDH': '行政豪华客房',
  'review.room.type.CPK': '行政尊贵客房',
  'review.room.type.CPT': '行政尊贵客房',
  'review.room.type.CPH': '行政尊贵客房',
  'review.room.type.STK': '特色套房',
  'review.room.type.STH': '特色套房',
  'review.room.type.SJK': '高级套房',
  'review.room.type.SJT': '高级套房',
  'review.room.type.SJH': '高级套房',
  'review.room.type.SDK': '豪华套房',
  'review.room.type.SDH': '豪华套房',
  'review.room.type.SRK': '皇室套房',
  'review.room.type.SPK': '总统套房',
  'review.room.type.TDK': '天际豪华客房',
  'review.room.type.TDH': '天际豪华客房',
  'review.room.type.THK': '天际海景客房',
  'review.room.type.THQ': '天际海景客房',
  'review.room.type.TSK': '天际半海景客房',
  'review.room.type.TSQ': '天际半海景客房',
  'review.room.type.HJH': '天际高级套房',
  'review.room.type.HVS': '天际海景套房',
  'review.room.type.DBD': '高级客房',
  'review.room.type.TWD': '高级客房',
  'review.room.type.QEA': '豪华客房',
  'review.room.type.TWA': '豪华客房',
  'review.room.type.QEE': '尊贵豪华客房',
  'review.room.type.TWE': '尊贵豪华客房',
  'review.room.type.KHC': '家庭客房',
  'review.room.type.KHA': '尊贵家庭客房',
  'review.room.type.QEC': '尊贵铂尔曼客房',
  'review.room.type.TWC': '尊贵铂尔曼客房',
  'review.room.type.QEAEF': '豪华行政客房',
  'review.room.type.KGAEF': '尊贵行政客房',
  'review.room.type.SKC': '柏宁套房',
  'review.room.type.SUP': '高級雙人客房',
  'review.room.type.AWM': 'Anti-window (4 Ft)',
  'review.room.type.TD': 'Tower Double',
  'review.room.type.DLX': 'Deluxe Room',
  //DWC
  'review.room.type.SUD': '高级客房',
  'review.room.type.SUH': '高级客房',
  'review.room.type.SUK': '高级客房',
  'review.room.type.SUT': '高级客房',
  'review.room.type.GCUD': '高级客房 (连景观)',
  'review.room.type.GCUK': '高级客房 (连景观)',
  'review.room.type.GCUT': '高级客房 (连景观)',
  'review.room.type.PDEQ': '尊尚铜锣湾城市景观客房',
  'review.room.type.PDED': '尊尚铜锣湾城市景观客房',
  'review.room.type.PDEK': '尊尚铜锣湾城市景观客房',
  'review.room.type.PDET': '尊尚铜锣湾城市景观客房',
  'review.room.type.PDCK': '尊尚跑马地马场景观客房',
  'review.room.type.PDCQ': '尊尚跑马地马场景观客房',
  'review.room.type.PDCT': '尊尚跑马地马场景观客房',
  'review.room.type.PDCD': '尊尚跑马地马场景观客房',
  'review.room.type.DTR': '豪华三人客房',
  'review.room.type.DFQ': '豪华家庭四人客房',
  'review.room.type.EFQ': '豪华家庭四人客房',
  'review.room.type.ESK': '行政套房',
  'review.room.type.EST': '行政套房',

  /** Confirm */
  'confirm.title': '预先登记程序已完成',
  'confirm.reminder': '您可以在到达我们的酒店时使用客服机械人。',
  'confirm.reminder2': '请在登记入住时出示登记二维码。',
  'confirm.thankyou': '谢谢',
  'confirm.deposit':
    '为了加快入住手续，已全额预付房费的客人无需支付押金。任何额外费用需要直接支付，不能计入房帐。',
  'confirm.qrcode.err': '二维码数值不正确',
  'confirm.notice.title': '特别通告:',
  'confirm.notice.covid19.title': '针对新型冠状病毒的预防措施',
  'confirm.notice.covid19.detail': '有关更多详细信息，请查看 ',
  'confirm.notice.covid19.link':
    'https://www.royalplaza.com.hk/zh/precautionary-measures-for-novel-coronavirus/',
  'confirm.notice.policy.title': '隐私政策声明和免责声明',
  'confirm.notice.policy.detail': '有关更多详细信息，请查看 ',
  'confirm.notice.policy.link':
    'https://www.royalplaza.com.hk/zh/disclaimer-privacy-statement/',

  /** Registration Page content */
  'reg.title': '预先登记',
  'reg.reminder': '请在下面填写您的个​​人详细信息，然后点击“确认”按钮',
  'reg.wechat.reminder': '请使用您的微信扫描以下二维码。',
  'reg.firstName': '名字',
  'reg.lastName': '姓氏',
  'reg.email': '個人电子邮件',
  'reg.address1': '地址 1',
  'reg.address2': '地址 2',
  'reg.pleaseSelect': '请选择',
  'reg.country': '国家或地区',
  'reg.nationality': '国籍',
  'reg.zip.code': '邮政编码',
  'reg.arrival.time': '预计到达时间',
  'reg.flightArrival.time': '航班抵港时间 / 预计抵港时间',
  'reg.arrival.hr': '时',
  'reg.arrival.min': '分',
  'reg.arrival.subject': '需视乎供应情况而定',
  'reg.tel': '手机号码',
  'reg.passportNumber': '护照号码',
  'reg.dateOfBirth': '出生日期',
  'reg.flightNumber': '航班編號',
  'reg.flightArrivalTime': '到達時間',
  'reg.evoucher': '接收酒店优惠及推广资讯（透过电子邮件，电话讯息及社交媒体）',
  'reg.evoucher.yes/no': '接受/拒绝',
  'reg.evoucher.yes': '接受',
  'reg.evoucher.no': '拒绝',
  'reg.method.inst.msg': '即时通讯方式',
  'reg.method.inst.msg.select': '选取通讯方式',
  'reg.method.inst.msg.sms': '短信',
  'reg.method.inst.msg.email': '电子邮件',
  'reg.method.inst.msg.whatsapp': 'Whatsapp',
  'reg.method.inst.msg.wechat': '微信',

  'hdf.ask': '要填寫健康申报表嗎?',
  'hdf.title': '健康申报表',
  'hdf.highRiskWarning.title': '覆核內容',
  'hdf.highRiskWarning.message': '请重新检视或联络酒店获取进一步的协助',
  'hdf.sign.title': '我聲明所填寫的資料皆為屬實',
  'hdf.sign.text': '請在下方簽署:',
  'hdf.sign.clear': '清除',

  'hdf.btn.fill': '填写',
  'hdf.btn.addGuest': 'Add Guest',
  'hdf.btn.signAndConfirm': '签署并确认',
  'hdf.btn.submitForm': '确认',
  'hdf.btn.back': '返回',
  'hdf.btn.backToReview': '返回注册',

  'hdf.guestName': '客户名称',
  'hdf.accompanyName': '伴随人',
  'hdf.familyName': '姓氏',
  'hdf.firstName': '名字',
  'hdf.dateOfBirth': '出生日期',
  'hdf.sex': '性别',
  'hdf.sex.m': '男',
  'hdf.sex.f': '女',
  'hdf.passport': '护照/身份证号码',
  'hdf.nationality': '国籍地区',
  'hdf.comeFrom': '旅游自',
  'hdf.arrivalDate': '抵港日期',
  'hdf.placesVisitedInSevenDays': '请列出过去七日到访的国家地区和城市',
  'hdf.contactWithCovid': '在过去七天内阁下有否与确诊新冠肺炎人士接触过?',
  'hdf.contactWithCovid.yes': '是',
  'hdf.contactWithCovid.no': '否',
  'hdf.symptom': '阁下有没有以下症状',
  'hdf.symptom.no': '否',
  'hdf.symptom.fever': '发烧',
  'hdf.symptom.breathingDifficulty': '呼吸困难',
  'hdf.symptom.cough': '咳',
  'hdf.symptom.diarrhoea': '腹泻',
  'hdf.symptom.headache': '头痛',
  'hdf.symptom.musclePain': '肌肉痛',
  'hdf.symptom.runnyNose': '流鼻水',
  'hdf.symptom.soreThroat': '咽喉痛',
  'hdf.symptom.stuffyNose': '鼻塞',
  'hdf.symptom.vomiting': '呕吐',
  'hdf.contact': '未来七天的联系方式',
  'hdf.contact.phone': '电话/手提号码',
  'hdf.email': '电邮',
  'hdf.address': '联系地址',

  'btn.confirm': '确认',
  'btn.hdf.form': '健康申報表',
  'btn.reset': '重设',
  'btn.cancel': '取消',

  'country.AF': '阿富汗',
  'country.AL': '阿尔巴尼亚',
  'country.DZ': '阿尔及利亚',
  'country.AS': '美属萨摩亚',
  'country.AD': '安道尔',
  'country.AO': '安哥拉',
  'country.AI': '安圭拉/圣基茨',
  'country.AQ': '南极洲',
  'country.AG': '安提瓜和巴布达',
  'country.AR': '阿根廷',
  'country.AM': '亚美尼亚',
  'country.AW': '阿鲁巴岛',
  'country.AU': '澳大利亚',
  'country.AT': '奥地利',
  'country.AZ': '阿塞拜疆',
  'country.BS': '巴哈马',
  'country.BH': '巴林',
  'country.BD': '孟加拉国国',
  'country.BB': '巴巴多斯',
  'country.BC': '北京',
  'country.BY': '白俄罗斯',
  'country.BE': '比利时',
  'country.BZ': '伯利兹',
  'country.BJ': '贝宁',
  'country.BM': '百慕大',
  'country.BT': '不丹',
  'country.BO': '玻利维亚',
  'country.BQ': '博奈尔岛，萨巴岛，圣尤斯达休',
  'country.BA': '波斯尼亚黑塞哥维那',
  'country.BW': '博茨瓦纳',
  'country.BV': '布维岛',
  'country.BR': '巴西',
  'country.IO': '英属印度洋领地',
  'country.BN': '文莱达鲁萨兰国',
  'country.BG': '保加利亚',
  'country.BF': '布基纳法萨',
  'country.BI': '布隆迪',
  'country.KH': '柬埔寨',
  'country.CM': '喀麦隆',
  'country.CA': '加拿大',
  'country.CV': '佛得角',
  'country.KY': '开曼群岛',
  'country.CF': '中非共和国',
  'country.TD': '乍得',
  'country.CL': '智利',
  'country.CN': '中国',
  'country.CX': '圣诞岛',
  'country.CC': '科科斯（基林）群岛',
  'country.CO': '哥伦比亚',
  'country.KM': '科摩罗',
  'country.CG': '刚果',
  'country.CD': '刚果民主共和国',
  'country.CK': '库克岛',
  'country.CR': '哥斯达黎加',
  'country.CI': '科特迪瓦',
  'country.HR': '克罗地亚',
  'country.CU': '古巴',
  'country.CW': '库拉索',
  'country.CY': '塞浦路斯',
  'country.CZ': '捷克共和国',
  'country.DK': '丹麦',
  'country.DJ': '吉布提',
  'country.DM': '多米尼加',
  'country.DO': '多米尼加共和国',
  'country.TP': '东帝汶',
  'country.EC': '厄瓜多尔',
  'country.EG': '埃及',
  'country.SV': '萨尔瓦多',
  'country.GQ': '赤道几内亚',
  'country.ER': '厄立特里亚',
  'country.EE': '爱沙尼亚',
  'country.ET': '埃塞俄比亚',
  'country.FK': '马尔维纳斯群岛',
  'country.FO': '法罗群岛',
  'country.FJ': '斐济',
  'country.FI': '芬兰',
  'country.FS': '佛山市',
  'country.FR': '法国',
  'country.FX': '法国',
  'country.GF': '法属圭亚那',
  'country.PF': '法属波利尼西亚',
  'country.TF': '法国南部地区',
  'country.GA': '加蓬',
  'country.GM': '冈比亚',
  'country.GE': '佐治亚州',
  'country.DE': '德国',
  'country.GH': '加纳',
  'country.GI': '直布罗陀',
  'country.GB': '大不列颠',
  'country.GR': '希腊',
  'country.GL': '格陵兰',
  'country.GD': '格林纳达',
  'country.GP': '瓜德罗普岛/圣马丁',
  'country.GU': '关岛',
  'country.GZ': '广州',
  'country.GT': '危地马拉',
  'country.GN': '几内亚',
  'country.GW': '几内亚比绍',
  'country.GY': '圭亚那',
  'country.HT': '海地',
  'country.HM': '克岛和麦当劳群岛',
  'country.HN': '洪都拉斯',
  'country.HK': '香港',
  'country.HU': '匈牙利',
  'country.IS': '冰岛',
  'country.IN': '印度',
  'country.ID': '印度尼西亚',
  'country.IR': '伊朗',
  'country.IQ': '伊拉克',
  'country.IE': '爱尔兰',
  'country.IM': '马恩岛',
  'country.IL': '以色列',
  'country.IT': '意大利',
  'country.JM': '牙买加',
  'country.JP': '日本',
  'country.JO': '约旦',
  'country.KZ': '哈萨克斯坦斯坦',
  'country.KE': '肯尼亚',
  'country.KI': '基里巴斯',
  'country.KR': '韩国',
  'country.KP': '朝鲜民主主义人民共和国',
  'country.KW': '科威特',
  'country.KG': '吉尔吉斯斯坦',
  'country.LA': '老挝',
  'country.LV': '拉脱维亚',
  'country.LB': '黎巴嫩',
  'country.LS': '莱索托',
  'country.LR': '利比里亚',
  'country.LY': '阿拉伯利比亚民众国',
  'country.LI': '列支敦士登',
  'country.LT': '立陶宛',
  'country.LU': '卢森堡',
  'country.MO': '澳门',
  'country.MK': '马其顿',
  'country.MG': '马达加斯加',
  'country.MW': '马拉维',
  'country.MY': '马来西亚',
  'country.MV': '马尔代夫',
  'country.ML': '马里',
  'country.MT': '马耳他',
  'country.MH': '马绍尔群岛',
  'country.MQ': '马提尼克岛',
  'country.MR': '毛里塔尼亚',
  'country.MU': '毛里求斯',
  'country.YT': '马约特岛岛',
  'country.MX': '墨西哥',
  'country.FM': '密克罗尼西亚联邦',
  'country.MI': '中途岛',
  'country.MD': '摩尔多瓦',
  'country.MC': '摩纳哥',
  'country.MN': '蒙古',
  'country.ME': '黑山共和国',
  'country.MS': '蒙特塞拉特',
  'country.MA': '摩洛哥',
  'country.MZ': '莫桑比克',
  'country.MM': '缅甸',
  'country.NA': '纳米比亚',
  'country.NR': '瑙鲁',
  'country.NP': '尼泊尔',
  'country.NL': '荷兰',
  'country.AN': '荷属安的列斯',
  'country.NC': '新喀里多尼亚',
  'country.NZ': '新西兰',
  'country.NI': '尼加拉瓜',
  'country.NE': '尼日尔',
  'country.NG': '尼日利亚',
  'country.NU': '纽埃',
  'country.NF': '诺福克岛',
  'country.MP': '北马里亚纳群岛',
  'country.NO': '挪威',
  'country.OM': '阿曼',
  'country.PK': '巴基斯坦',
  'country.PW': '帕劳',
  'country.PA': '巴拿马',
  'country.PG': '巴布亚新几内亚',
  'country.PY': '巴拉圭',
  'country.PE': '秘鲁',
  'country.PH': '菲律宾',
  'country.PN': '皮特凯恩',
  'country.PL': '波兰',
  'country.PT': '葡萄牙',
  'country.PR': '波多黎各',
  'country.QA': '卡塔尔',
  'country.RE': '团圆',
  'country.RO': '罗马尼亚',
  'country.RU': '俄国',
  'country.RQ': '俄罗斯联邦',
  'country.RW': '卢旺达',
  'country.SH': '圣海伦娜',
  'country.KN': '圣基茨和尼维斯',
  'country.LC': '圣卢西亚',
  'country.PM': '圣皮埃尔和密克隆群岛',
  'country.VC': '圣文森特和格林纳丁斯',
  'country.WS': '萨摩亚',
  'country.SM': '圣马力诺',
  'country.ST': '圣多美和普林西比',
  'country.SA': '沙特阿拉伯',
  'country.SN': '塞内加尔',
  'country.RS': '塞尔维亚',
  'country.SC': '塞舌尔',
  'country.SS': '上海',
  'country.SX': '深圳',
  'country.SL': '塞拉利昂',
  'country.SG': '新加坡',
  'country.SK': '斯洛伐克',
  'country.SI': '斯洛文尼亚',
  'country.SB': '所罗门群岛',
  'country.SO': '索马里',
  'country.ZA': '南非',
  'country.GS': '南乔治亚岛和南桑威奇群岛',
  'country.ES': '西班牙',
  'country.LK': '斯里兰卡',
  'country.SD': '苏丹',
  'country.SR': '苏里南',
  'country.SJ': '斯瓦尔巴群岛和扬马延群岛',
  'country.SZ': '斯威士兰',
  'country.SE': '瑞典',
  'country.CH': '瑞士',
  'country.SY': '叙利亚',
  'country.TW': '台湾',
  'country.TJ': '塔吉克斯坦',
  'country.TZ': '坦桑尼亚',
  'country.TH': '泰国',
  'country.TG': '多哥',
  'country.TK': '托克劳',
  'country.TO': '汤加',
  'country.TT': '特立尼达和多巴哥',
  'country.TN': '突尼斯',
  'country.TR': '土耳其',
  'country.TM': '土库曼斯坦',
  'country.TC': '特克斯和凯科斯群岛',
  'country.TV': '图瓦卢',
  'country.UG': '乌干达',
  'country.UA': '乌克兰',
  'country.AE': '阿拉伯联合酋长国',
  'country.UK': '英国',
  'country.US': '美国',
  'country.UM': '美国本土外小岛屿',
  'country.XX': '未知',
  'country.UY': '乌拉圭',
  'country.UZ': '乌兹别克斯坦',
  'country.VU': '瓦努阿图',
  'country.VA': '梵蒂冈城国',
  'country.VE': '委内瑞拉',
  'country.VN': '越南',
  'country.VG': '英属维京群岛',
  'country.VI': '美国维京群岛',
  'country.WF': '瓦利斯群岛和富图纳群岛',
  'country.EH': '撒哈拉沙漠西部',
  'country.YE': '也门',
  'country.ZR': '扎伊尔',
  'country.ZM': '赞比亚',
  'country.ZW': '津巴布韦',

  'country.AX': '奥兰群岛',
  'country.AC': '升天岛',
  'country.BL': '圣巴托洛缪岛',
  'country.GG': '根西岛',
  'country.JE': '泽西岛',
  'country.MF': '圣马丁（法语部分）',
  'country.PS': '被占领的巴勒斯坦领土',
  'country.TA': '特里斯坦达库尼亚',
  'country.TL': '东帝汶',
  'country.XK': '科索沃',

  'country.SS1': '南苏丹',
  'country.SX1': '圣马丁（荷兰语部分）',
};
