import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import dayjs from 'dayjs';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { countryCode, getPhonePlaceholder } from '../constants';
import { Primary, Secondary } from '../theme/colors';
import './Registration.css';

const { REACT_APP_API_URL } = process.env;

const MyDatePicker = (props: { name: string }) => {
  const [field, meta, helpers] = useField(props.name);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <DatePicker
      {...field}
      dateFormat="yyyy-MM-dd"
      selected={value}
      onChange={(date) => setValue(date)}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  btnWhite: {
    width: '50%',
    background: Primary,
    color: Secondary,
  },
  btnGrey: {
    width: '50%',
    backgroundColor: '#000000',
    color: '#000',
  },
}));

var hrs;
var hrOptions: any[] = [];
//for(hrs=0; hrs<24; hrs++){
for (hrs = 12; hrs < 24; hrs++) {
  const padHrs = hrs < 10 ? '0' + hrs : hrs;
  hrOptions.push(
    <option key={'hrs_' + padHrs} value={padHrs}>
      {padHrs + ':00'}
    </option>
  );
}
hrOptions.push(
  <option key={'hrs_00'} value={'00'}>
    {'00:01'}
  </option>
);

const excludeAutoFillEmailList = [
  'agoda-messaging.com',
  'guest.booking.com',
  'm.expediapartnercentral.com',
  'guest.trip.com',
];

function getInitialValues() {
  let id = localStorage.getItem('id');
  //let reservation_id = localStorage.getItem("reservationId");
  let profileId = localStorage.getItem('profileId');
  let confirmationNumber = localStorage.getItem('confirmationNumber');
  let firstName = localStorage.getItem('firstName');
  let lastName = localStorage.getItem('lastName');
  let address1 = localStorage.getItem('address1') ?? '';
  let address2 = localStorage.getItem('address2') ?? '';
  let country = localStorage.getItem('country') ?? '';
  let zipCode = localStorage.getItem('zipCode') ?? '';
  let tel = localStorage.getItem('tel') ?? '';
  let flightNumber = localStorage.getItem('flightNumber') ?? '';
  let flightArrivalTime = localStorage.getItem('flightArrivalTime') ?? '';
  let email = excludeAutoFillEmailList.some((excludeEmail) =>
    localStorage.getItem('email')?.toLowerCase()?.includes(excludeEmail)
  )
    ? ''
    : localStorage.getItem('email');
  let passportNumber = '';
  let dateOfBirth = localStorage.getItem('dateOfBirth') ?? '';
  if (id === null || id === '' || id === 'undefined') {
    id = '';
  }
  if (firstName === null || firstName === '' || firstName === 'undefined') {
    firstName = '';
  }
  if (lastName === null || lastName === '' || lastName === 'undefined') {
    lastName = '';
  }
  return {
    id: id,
    //reservationId: reservation_id,
    profileId: profileId,
    confirmationNumber: confirmationNumber,
    firstName: firstName,
    lastName: lastName,
    address1: address1,
    address2: address2,
    country: country,
    zipCode: zipCode,
    tel: tel,
    flightNumber: flightNumber,
    flightArrivalTime: flightArrivalTime,
    email: email,
    passportNumber: passportNumber,
    dateOfBirth: dateOfBirth,
  };
}

export const Registration = (props: { token: string; hotelId: string }) => {
  // props.hotel["id"] = "62c7ffbac0bd1cf8989046c7"
  const isDwc =
    props?.hotelId === '62c7ffbac0bd1cf8989046c7' ||
    props?.hotelId === '6434be5690f99a6e3787ec96' ||
    props?.hotelId === '6449e7e5abd582feb900edbd';
  const isPlaza = props?.hotelId === '633e5647d02dd2bf990ed96c';
  const isHigm = props?.hotelId === '656fd2bbb6cfb4be38a20a05';
  //localStorage.clear();
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const initialValues = getInitialValues();
  const [resData, setResData] = useState({});
  const [pollState, setPollState] = useState(false);
  const [wechatState, setWechatState] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  const wechatPollUrl = REACT_APP_API_URL + 'notifications/wechat/subscribed';
  const url = REACT_APP_API_URL + 'prearrivals/register/' + initialValues.id;

  const settings = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: 'Bearer ' + props.token,
    },
    timeout: 6000000,
  };

  //const pollData = { "reservation_id": initialValues.reservationId }
  const pollData = { confirmation_number: initialValues.confirmationNumber };

  useEffect(() => {
    if (wechatState) {
      // Do the logic if instant mehod is wechat
      const interval = setInterval(async () => {
        if (pollState === true) {
          try {
            const wechatResponse = await axios.post(
              wechatPollUrl,
              pollData,
              settings
            );
            let rtnData = wechatResponse.data;
            if (rtnData.subscribed === true) {
              setPollState(false);
              clearInterval(interval);
              try {
                //setPollState(false);
                setIsSubmittingData(true);
                const response = await axios.post(url, resData, settings);
                setIsSubmittingData(false);
                //clearInterval(interval);
                history.push('confirm');
              } catch (error) {
                if (axios.isAxiosError(error)) {
                  if (
                    error.response &&
                    error.response.data !== 'Data already exist'
                  ) {
                    console.log('Error Section 1 ');
                    console.log(error);
                    //error.response !== undefined?alert(error.response.data):alert(error.toString());
                    setDisableBtn(false);
                    alert(intl.formatMessage({ id: 'error.message2' }));
                  }
                }
              }
            } else {
              setPollState(true);
            }
          } catch (error) {
            setPollState(true);
            console.log('Error Section 2 ');
            console.log(error);
            setDisableBtn(false);
            alert(intl.formatMessage({ id: 'error.message2' }));
            //error.response !== undefined?alert(error.response.data):alert(error.toString());
          }
        }
      }, 1000);
    }
  }, [wechatState, pollState]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <span className={wechatState ? classes.hide : classes.show}>
          <h5 style={{ color: '#808080', marginBottom: '60px' }}>
            <FormattedMessage id="reg.reminder" />
          </h5>
        </span>
        <span className={wechatState ? classes.show : classes.hide}>
          <h5 style={{ color: '#808080', marginBottom: '60px' }}>
            <FormattedMessage id="reg.wechat.reminder" />
          </h5>
        </span>
        <Formik
          initialValues={{
            firstName: initialValues.firstName,
            lastName: initialValues.lastName,
            address1: initialValues.address1,
            address2: initialValues.address2,
            country: initialValues.country,
            zipCode: initialValues.zipCode,
            tel: initialValues.tel,
            flightNumber: initialValues.flightNumber,
            flightArrivalTime: initialValues.flightArrivalTime,
            email: initialValues.email,
            passportNumber: initialValues.passportNumber,
            dateOfBirth:
              initialValues.dateOfBirth !== undefined &&
              initialValues.dateOfBirth !== '' &&
              initialValues.dateOfBirth !== null
                ? new Date(initialValues.dateOfBirth)
                : '',
            methodOfInstMsg: '',
            arrivalHrs: '',
            arrivalMins: '00',
            evoucher: undefined,
          }}
          validationSchema={yup.object().shape({
            //firstName: yup.string().required(intl.formatMessage({ id: 'reg.firstName' })+ " " + intl.formatMessage({ id: 'error.required' })),
            //lastName: yup.string().required(intl.formatMessage({ id: 'reg.lastName' })+ " " + intl.formatMessage({ id: 'error.required' })),
            email: yup
              .string()
              .email()
              .required(
                intl.formatMessage({ id: 'reg.email' }) +
                  ' ' +
                  intl.formatMessage({ id: 'error.required' })
              ),
            address1: !isDwc
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.address1' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            passportNumber:
              isDwc && props?.hotelId !== '6449e7e5abd582feb900edbd'
                ? yup
                    .string()
                    .required(
                      intl.formatMessage({ id: 'reg.passportNumber' }) +
                        ' ' +
                        intl.formatMessage({ id: 'error.required' })
                    )
                : yup.string().optional(),
            dateOfBirth: isDwc
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.dateOfBirth' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            country: yup
              .string()
              .required(
                intl.formatMessage({ id: 'reg.country' }) +
                  ' ' +
                  intl.formatMessage({ id: 'error.required' })
              ),
            //zipCode: yup.string().required(intl.formatMessage({ id: 'reg.zip.code' })+ " " + intl.formatMessage({ id: 'error.required' })),
            tel: yup
              .string()
              .required(
                intl.formatMessage({ id: 'reg.tel' }) +
                  ' ' +
                  intl.formatMessage({ id: 'error.required' })
              ),
            arrivalHrs: !isDwc
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.arrival.hr' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            //arrivalMins: yup.string().required(intl.formatMessage({ id: 'reg.arrival.min' })+ " " + intl.formatMessage({ id: 'error.required' })),
            // methodOfInstMsg: yup.string().required(intl.formatMessage({ id: 'reg.method.inst.msg' }) + " " + intl.formatMessage({ id: 'error.required' })),

            /* following 2 fields for DWC */
            //flightNumber: yup
            //  .string()
            //  .matches(
            //    /\b([A-Z]{2}|[A-Z]\d|\d[A-Z])\s?\d{3,4}\b/,
            //    intl.formatMessage({ id: 'error.message.flightNumber' })
            //  )
            //  .optional(),
            flightArrivalTime: isDwc
              ? yup
                  .string()
                  .matches(
                    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
                    intl.formatMessage({
                      id: 'error.message.flightArrivalTime',
                    })
                  )
                  .required(
                    intl.formatMessage({
                      id: 'error.message.flightArrivalTime',
                    })
                  )
              : yup
                  .string()
                  .matches(
                    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
                    intl.formatMessage({
                      id: 'error.message.flightArrivalTime',
                    })
                  )
                  .optional(),
            evoucher: isPlaza
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'error.message.preference' })
                  )
              : yup.string().optional(),
          })}
          onSubmit={(values) => {
            if (initialValues.confirmationNumber) {
              localStorage.setItem(
                'confirmationNumber',
                initialValues.confirmationNumber
              );
            }
            let lang = localStorage.getItem('lang');
            if (lang === 'undefined' || lang === '' || lang === null) {
              lang = 'en';
            }

            const data = {
              // "pre-arrival_id": id,
              profile: {
                profileId: initialValues.profileId,
                bookingNumber: initialValues.confirmationNumber,
                firstName: initialValues.firstName,
                lastName: initialValues.lastName,
                address: {
                  address1: values.address1,
                  address2: values.address2,
                  countryCode: values.country,
                  zipCode: values.zipCode,
                },
                email: values.email,
                phoneNumber: values.tel || '',
                document:
                  values.passportNumber !== ''
                    ? {
                        documentNumber: values.passportNumber,
                      }
                    : undefined,
                dateOfBirth:
                  values.dateOfBirth !== undefined && values.dateOfBirth !== ''
                    ? dayjs(values.dateOfBirth).format('YYYY-MM-DD')
                    : undefined,
              },
              // "method_of_instant_message": values.methodOfInstMsg,
              flightNumber: values.flightNumber || '',
              // flightArrivalTime: values.flightArrivalTime || '',
              expectedArrivalTime: !isDwc
                ? values.arrivalHrs +
                  ':' +
                  (values.arrivalHrs === '00' ? '01' : values.arrivalMins)
                : values.flightArrivalTime,
              preferredLanguage: lang,
              evoucher: values.evoucher,
            };

            localStorage.setItem('email', values?.email || '');
            setResData(data);
            setDisableBtn(true);

            if (values.methodOfInstMsg === 'wechat') {
              //Handle Wechat logic here
              setWechatState(true);
              setPollState(true);

              //let qrUrl = "https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wxff7cbd131ba7c2c0&scene=1000&template_id=3bca5AANn-aYb6hOL-cWDiTATzrBAPO5aZ1VCn6O9h8&redirect_url=https://precheckin.rghk.com.hk/pre-arrival/notifications/wechat/subscribe&reserved="+initialValues.reservationId+"#wechat_redirect";
              let qrUrl =
                'https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wxff7cbd131ba7c2c0&scene=1000&template_id=3bca5AANn-aYb6hOL-cWDiTATzrBAPO5aZ1VCn6O9h8&redirect_url=http://smarthotel.bluepin.com.cn/pre-arrival/notifications/wechat/subscribe&reserved=' +
                initialValues.confirmationNumber +
                '_rgh' +
                '#wechat_redirect';
              setQrCodeUrl(qrUrl);
            } else {
              //Handle normal flow logic here
              axios
                .post(url, data, settings)
                .then(() => {
                  history.push('checkinpayment');
                })
                .catch(function (error) {
                  console.log('Error Section 3 ');
                  console.log(error);
                  setDisableBtn(false);
                  //error.response !== undefined?alert(error.response.data):alert(error.toString());
                  alert(intl.formatMessage({ id: 'error.message2' }));
                });
            }
          }}
          onReset={(values) => {
            //values.firstName = '';
            //values.lastName = '';
            // values.address1 = '';
            // values.address2 = '';
            // values.country = '';
            // values.zipCode = '';
            // values.tel = '';
            // values.email = '';
            values.methodOfInstMsg = '';
            values.arrivalHrs = '';
            values.arrivalMins = '';
            values.evoucher = undefined;
            setPollState(false);
            setWechatState(false);
          }}
        >
          {({ values, errors, handleBlur, handleReset, touched }) => (
            <Form className={classes.form} noValidate onReset={handleReset}>
              <div className={wechatState ? classes.show : classes.hide}>
                <Grid container spacing={2} id="qrCodeForm">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{ color: '#808080', textAlign: 'center' }}
                  >
                    <QRCode value={qrCodeUrl} level={'H'} size={250} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                    <Button
                      disabled={isSubmittingData}
                      variant="contained"
                      style={{
                        width: 250,
                        background: Primary,
                        color: Secondary,
                      }}
                      onClick={() => {
                        setPollState(false);
                        setWechatState(false);
                        setDisableBtn(false);
                      }}
                    >
                      <FormattedMessage id="btn.cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className={wechatState ? classes.hide : classes.show}>
                <Grid container spacing={2} id="detailForm">
                  <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                    <FormattedMessage id="reg.firstName" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {initialValues.firstName}
                  </Grid>

                  <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                    <FormattedMessage id="reg.lastName" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {initialValues.lastName}
                  </Grid>

                  <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                    <FormattedMessage id="reg.email" />
                    {isDwc ? '*' : ''}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="email"
                      type="text"
                      className={
                        'bluepin-input bluepin-placeholder' +
                        (errors.email && touched.email ? ' is-invalid' : '')
                      }
                      placeholder={intl.formatMessage({ id: 'reg.email' })}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                    <FormattedMessage id="reg.tel" />
                    {isDwc ? '*' : ''}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <PhoneInput
                      name="tel"
                      international
                      defaultCountry={
                        props?.hotelId === '6449e7e5abd582feb900edbd'
                          ? 'AU'
                          : 'HK'
                      }
                      value={values.tel}
                      placeholder={intl.formatMessage({ id: 'reg.tel' })}
                      className={
                        'bluepin-placeholder' +
                        (errors.tel && touched.tel ? ' is-invalid' : '')
                      }
                      onBlur={handleBlur}
                      onChange={(value) => {
                        //console.log(value);
                        values.tel = value;
                      }}
                      labels={getPhonePlaceholder(intl)}
                    />

                    <ErrorMessage
                      name="tel"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Grid>

                  {!isDwc ? (
                    <>
                      <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                        <FormattedMessage id="reg.address1" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="address1"
                          component="textarea"
                          rows={5}
                          maxLength={80}
                          className={
                            'bluepin-input bluepin-placeholder' +
                            (errors.address1 && touched.address1
                              ? ' is-invalid'
                              : '')
                          }
                          placeholder={intl.formatMessage({
                            id: 'reg.address1',
                          })}
                        />
                        <ErrorMessage
                          name="address1"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                        <FormattedMessage id="reg.address2" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="address2"
                          component="textarea"
                          rows={5}
                          maxLength={80}
                          className={
                            'bluepin-input bluepin-placeholder' +
                            (errors.address2 && touched.address2
                              ? ' is-invalid'
                              : '')
                          }
                          placeholder={intl.formatMessage({
                            id: 'reg.address2',
                          })}
                        />
                        <ErrorMessage
                          name="address2"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  {!isDwc && (
                    <>
                      <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                        <FormattedMessage id="reg.zip.code" />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Field
                          name="zipCode"
                          type="text"
                          className={
                            'bluepin-input bluepin-placeholder' +
                            (errors.zipCode && touched.zipCode
                              ? ' is-invalid'
                              : '')
                          }
                          placeholder={intl.formatMessage({
                            id: 'reg.zip.code',
                          })}
                        />
                        <ErrorMessage
                          name="zipCode"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                    <FormattedMessage
                      id={isDwc ? 'reg.nationality' : 'reg.country'}
                    />
                    {isDwc ? '*' : ''}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="country"
                      as="select"
                      className={
                        'form-control' +
                        (errors.country && touched.country ? ' is-invalid' : '')
                      }
                    >
                      {countryCode.map(
                        (x: { key: string; value: string; id: string }) => {
                          return (
                            <option key={x.key} value={x.value}>
                              {intl.formatMessage({ id: x.id })}
                            </option>
                          );
                        }
                      )}
                    </Field>
                    <ErrorMessage
                      name={isDwc ? 'nationality' : 'country'}
                      component="div"
                      className="invalid-feedback"
                    />
                  </Grid>

                  {isDwc && (
                    <>
                      {props?.hotelId !== '6449e7e5abd582feb900edbd' && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{ color: '#808080' }}
                          >
                            <FormattedMessage id="reg.passportNumber" />
                            {isDwc ? '*' : ''}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              name="passportNumber"
                              className={
                                'bluepin-input bluepin-placeholder' +
                                (errors.passportNumber ? ' is-invalid' : '')
                              }
                              placeholder={intl.formatMessage({
                                id: 'reg.passportNumber',
                              })}
                            />
                            <ErrorMessage
                              name="passportNumber"
                              component="div"
                              className="invalid-feedback"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                        <FormattedMessage id="reg.dateOfBirth" />
                        {isDwc ? '*' : ''}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MyDatePicker name="dateOfBirth" />
                        {errors.dateOfBirth && (
                          <div
                            className="invalid-feedback"
                            style={{ display: 'initial' }}
                          >
                            {`${intl.formatMessage({
                              id: 'reg.dateOfBirth',
                            })} ${intl.formatMessage({
                              id: 'error.required',
                            })}`}
                          </div>
                        )}
                      </Grid>
                    </>
                  )}
                  {!isDwc ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        style={{
                          color: '#808080',
                        }}
                      >
                        <FormattedMessage
                          id={
                            isDwc
                              ? 'reg.flightArrival.time'
                              : 'reg.arrival.time'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as="select"
                          className={
                            'form-control' +
                            (errors.arrivalHrs && touched.arrivalHrs
                              ? ' is-invalid'
                              : '')
                          }
                          name="arrivalHrs"
                          value={values.arrivalHrs}
                        >
                          <option value="">
                            {intl.formatMessage({ id: 'reg.arrival.hr' })}
                          </option>
                          {hrOptions}
                        </Field>
                        <ErrorMessage
                          name="arrivalHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                        {isHigm && (
                          <FormattedMessage id="reg.arrival.subject" />
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                        <FormattedMessage id="reg.flightNumber" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="flightNumber"
                          className={
                            'bluepin-input bluepin-placeholder' +
                            (errors.flightNumber && touched.flightNumber
                              ? ' is-invalid'
                              : '')
                          }
                          placeholder={'e.g. CX104'}
                        />
                        <ErrorMessage
                          name="flightNumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} style={{ color: '#808080' }}>
                        <FormattedMessage id="reg.flightArrivalTime" />
                        {isDwc ? '*' : ''}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="flightArrivalTime"
                          className={
                            'bluepin-input bluepin-placeholder' +
                            (errors.flightArrivalTime &&
                            touched.flightArrivalTime
                              ? ' is-invalid'
                              : '')
                          }
                          placeholder={'e.g. 18:20'}
                        />
                        <ErrorMessage
                          name="flightArrivalTime"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Grid>
                    </>
                  )}
                  {isPlaza && (
                    <>
                      <Grid item xs={12} sm={8} style={{ color: '#808080' }}>
                        <FormattedMessage id={'reg.evoucher'} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          as="select"
                          className={
                            'form-control' +
                            (errors.evoucher && touched.evoucher
                              ? ' is-invalid'
                              : '')
                          }
                          name="evoucher"
                          value={values.evoucher}
                        >
                          <option value={undefined}>
                            {intl.formatMessage({ id: 'reg.evoucher.yes/no' })}
                          </option>
                          <option value="true">
                            {intl.formatMessage({ id: 'reg.evoucher.yes' })}
                          </option>
                          <option value="false">
                            {intl.formatMessage({ id: 'reg.evoucher.no' })}
                          </option>
                        </Field>
                        <ErrorMessage
                          name="evoucher"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12}>
                    <div
                      style={{
                        margin: 0,
                        position: 'relative',
                        top: '50%',
                        left: '25%',
                      }}
                    >
                      <Button
                        className={
                          disableBtn ? classes.btnGrey : classes.btnWhite
                        }
                        type="submit"
                        fullWidth
                        variant="contained"
                        //color="primary"
                        disabled={disableBtn}
                      >
                        {disableBtn ? (
                          <CircularProgress size={24} />
                        ) : (
                          <FormattedMessage id="btn.confirm" />
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
