import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { QRCodeSVG } from 'qrcode.react';
import { Primary } from '../theme/colors';
import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  qrcode: {
    marginTop: theme.spacing(8),
  },
}));

export const Confirm = (props: { token: string; hotelId: string }) => {
  const classes = useStyles();
  const isPlaza = props?.hotelId === '633e5647d02dd2bf990ed96c';
  const url = REACT_APP_API_URL + 'notifications/prearrival-successful';
  const settings = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: 'Bearer ' + props.token,
    },
    timeout: 6000000,
  };
  // console.log(localStorage);
  let confirmationNumber = localStorage.getItem('confirmationNumber') ?? '';
  let email = localStorage.getItem('email') ?? '';
  let lang = localStorage.getItem('lang');
  if (lang === 'undefined' || lang === '' || lang === null) {
    lang = 'en';
  }

  useEffect(() => {
    if (confirmationNumber !== '') {
      try {
        const resData = {
          bookingNumber: confirmationNumber,
          email: email,
          language: lang,
        };
        const response = axios.post(url, resData, settings);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      }
    }
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper} style={{ marginLeft: '1rem' }}>
        <h4 style={{ color: Primary, marginBottom: '60px' }}>
          <FormattedMessage id="confirm.title" />
        </h4>
        {isPlaza ? (
          <>
            <h5 style={{ color: '#808080', textAlign: 'center' }}>
              <FormattedMessage id="confirm.deposit" />
            </h5>
            {confirmationNumber !== '' && (
              <>
                <Grid
                  item
                  xs={12}
                  className={classes.qrcode}
                  style={{ marginBottom: '30px' }}
                >
                  <QRCodeSVG
                    value={confirmationNumber}
                    level={'H'}
                    size={300}
                  />
                </Grid>
                <h5 style={{ color: '#808080' }}>
                  <FormattedMessage id="confirm.reminder" />
                </h5>
                <h5 style={{ color: '#808080' }}>
                  <FormattedMessage id="confirm.reminder2" />
                </h5>
                <h5 style={{ color: '#808080' }}>
                  <FormattedMessage id="confirm.thankyou" />
                </h5>
              </>
            )}
          </>
        ) : (
          <>
            <h5 style={{ color: '#808080' }}>
              <FormattedMessage id="confirm.reminder" />
            </h5>
            <>
              {confirmationNumber !== '' && (
                <>
                  <h5 style={{ color: '#808080' }}>
                    <FormattedMessage id="confirm.reminder2" />
                  </h5>
                  <h5 style={{ color: '#808080' }}>
                    <FormattedMessage id="confirm.thankyou" />
                  </h5>

                  <Grid item xs={12} className={classes.qrcode}>
                    <QRCodeSVG
                      value={confirmationNumber}
                      level={'H'}
                      size={300}
                    />
                  </Grid>
                </>
              )}
            </>
          </>
        )}
      </div>
    </Container>
  );
};
