import {
  Box,
  Checkbox,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { AvailablePaymentMethod } from 'types/AvailablePaymentMethod';
import { BillItem } from 'types/BillItem';
import { Deposit } from 'types/Deposit';
import { MakePaymentRequestBody } from 'types/MakePaymentRequestBody';
import { QrPayment } from 'types/QrPayment';
import { WebPayment } from 'types/WebPayment';
import useInterval from 'use-interval';
import {
  checkQrPaymentStatus,
  checkWebPaymentStatus,
  getAvailablePaymentMethods,
  getPrearrivalBooking,
  makeQrPayment,
  makeWebPayment,
  payPlanetDepositByToken,
} from '../api';
import { Primary, Secondary } from '../theme/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    marginRight: theme.spacing(15),
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  btnWhite: {
    width: '100%',
    background: Primary,
    color: Secondary,
  },
  webPaymentWrapper: {
    textAlign: 'center',
    height: '100vh',
    width: '100%',
    fontSize: '.5rem',
    '& *': {
      fontSize: '.5rem',
    },
  },
}));

export const CheckInPayment: FC<{
  hotelId: string;
  hotelConfig: any;
  id: string;
  bookingNumber: string;
  status?: 'paymentSuccess' | 'depositSuccess' | 'paymentError' | 'undefined';
}> = (props) => {
  const isParkLane = props.hotelId === '6311619228fa6745ccf22e56';
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const [paymentMethods, setPaymentMethods] = useState<
    AvailablePaymentMethod[]
  >([]);
  const [deposits, setDeposits] = useState<Deposit[]>();
  const [billItems, setBillItems] = useState<BillItem[]>();
  const [depositRequired, setDepositRequired] = useState<
    'compulsory' | 'optional' | 'skip'
  >();
  const [roomRateRequired, setRoomRateRequired] = useState<
    'compulsory' | 'optional' | 'skip'
  >();
  const [isDepositPaid, setIsDepositPaid] = useState<boolean>();
  const [webPayment, setWebPayment] = useState<WebPayment>();
  const [qrPayment, setQrPayment] = useState<QrPayment>();
  const [chosenPayment, setChosenPayment] = useState<MakePaymentRequestBody>();
  const [isQrPaymentSuccess, setIsQrPaymentSuccess] = useState<
    boolean | undefined
  >();
  const [isWebPaymentSuccess, setIsWebPaymentSuccess] = useState<
    boolean | undefined
  >();
  const [saveCreditCard, setSaveCreditCard] = useState<boolean | undefined>(
    undefined
  );

  const [paymentErrorCode, setPaymentErrorCode] = useState<string>();

  const [webPaymentToken, setWebPaymentToken] = useState<string | undefined>();
  const [webPaymentTokenDescription, setWebPaymentTokenDescription] =
    useState<string>();
  const [paymentFlow, setPaymentFlow] = useState<
    | 'askSaveTokenPayment'
    | 'askSaveTokenDeposit'
    | 'paymentInit'
    | 'loadingPayment'
    | 'depositInit'
    | 'paymentSuccess'
    | 'payingDespoitByToken'
    | 'askUseToken'
    | 'webPayment'
    | 'qrPayment'
    | 'depositSuccess'
    | 'paymentError'
    | 'chooseDepositMethods'
  >();
  const [paymentVendor, setPaymentVendor] = useState<string>();
  const [isLoadingGetPrearrival, setIsLoadingGetPrearrival] =
    useState<boolean>(false);

  useEffect(() => {
    console.log(roomRateRequired);
  }, [roomRateRequired]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('id')) {
      queryParams.delete('id');
    }
    if (queryParams.has('token')) {
      queryParams.delete('token');
    }
    if (queryParams.has('paymentstatus')) {
      queryParams.delete('paymentstatus');
    }
    if (queryParams.has('Ref')) {
      queryParams.delete('Ref');
    }
    history.replace({
      search: queryParams.toString(),
    });
  }, []);

  useEffect(() => {
    if (chosenPayment) {
      setPaymentVendor(
        props.hotelConfig.payments.find(
          (x: any) =>
            x.type === chosenPayment.paymentFlowType &&
            x.method.code === chosenPayment.paymentMethodCode
        )?.config.vendor
      );
    }
  }, [chosenPayment, props.hotelConfig]);

  useEffect(() => {
    if (paymentVendor && webPayment) {
      // if (paymentVendor === 'asiaPay') {
      //   setTimeout(() => {
      //     window.location.replace(webPayment.link);
      //   });
      // }
      if (paymentVendor === 'dbs' || paymentVendor === 'asiaPay') {
        // Hacky way to open a new tab for DBS payment, due to iOS limitation that window.open should be on main thread
        // https://stackoverflow.com/a/70463940
        setTimeout(() => {
          window.open(webPayment.link, '_blank');
        });
      }
    }
  }, [webPayment, paymentVendor]);

  useEffect(() => {
    // console.log(props);
    if (props.status !== 'undefined') {
      setPaymentFlow(props.status);
    }
    if (props.id !== '') {
      setIsLoadingGetPrearrival(true);
      getPrearrivalBooking(props.id)
        .then((data) => {
          if (data.isDepositPaid === false) {
            setDeposits(data.deposits);
          } else {
            setDeposits([]);
          }
          setBillItems(data.billItems);
        })
        .finally(() => {
          setIsLoadingGetPrearrival(false);
        });
    }
    console.log(props);
    if (props.hotelConfig) {
      setRoomRateRequired(props.hotelConfig.prearrivalRules?.roomRateRequired);
      setDepositRequired(props.hotelConfig.prearrivalRules?.depositRequired);
    }
  }, [props]);

  useEffect(() => {
    console.log(paymentFlow);
    if (paymentFlow === 'depositInit' || paymentFlow === 'paymentInit') {
      getAvailablePaymentMethods().then((response) => {
        setPaymentMethods(
          response.filter((x) => x.showOn.includes('prearrival'))
        );
      });
    }
  }, [paymentFlow]);

  useEffect(() => {
    if (paymentFlow === undefined) {
      if (billItems && deposits) {
        if (isParkLane === true) {
          if (
            billItems.length > 0 &&
            billItems.reduce((prev, x) => prev + x.totalAmount, 0) > 0
          ) {
            setPaymentFlow('askSaveTokenPayment');
          } else {
            onPaymentContinueClick();
          }
        } else {
          if (
            billItems.length > 0 &&
            billItems.reduce((prev, x) => prev + x.totalAmount, 0) > 0 &&
            roomRateRequired !== 'skip'
          ) {
            setPaymentFlow('paymentInit');
          } else if (depositRequired !== 'skip' && deposits.length > 0) {
            setPaymentFlow('depositInit');
          } else {
            onEndPayment();
          }
        }
      }
    }
  }, [billItems, paymentFlow, deposits, history, isDepositPaid]);

  useEffect(() => {
    if (paymentFlow === 'depositInit' && deposits) {
      if (
        isDepositPaid === true ||
        deposits.length === 0 ||
        deposits.reduce((prev, x) => prev + x.amount, 0) === 0
      ) {
        history.push('confirm');
      }
    }
  }, [paymentFlow, isDepositPaid, deposits, history]);

  useInterval(
    () => {
      if (qrPayment !== undefined && chosenPayment !== undefined) {
        checkQrPaymentStatus({
          ...chosenPayment,
          startTime: qrPayment.startTime,
          transactionCode: qrPayment.transactionCode,
        })
          .then((response) => {
            console.log('PAY OK');
            setIsQrPaymentSuccess(true);
            setPaymentFlow('paymentSuccess');
          })
          .catch((e: any) => {
            if (e.response?.data?.code !== 'P1') {
              setPaymentFlow('paymentError');
              setQrPayment(undefined);
            }
          });
      }
    },
    qrPayment !== undefined &&
      chosenPayment !== undefined &&
      isQrPaymentSuccess !== true
      ? 5000
      : null
  );

  useInterval(
    () => {
      if (
        webPayment !== undefined &&
        chosenPayment !== undefined &&
        isWebPaymentSuccess !== true
      ) {
        checkWebPaymentStatus({
          ...chosenPayment,
          transactionCode: webPayment.merchantRef,
        })
          .then((response) => {
            console.log('PAY OK');
            setIsWebPaymentSuccess(true);
            if (chosenPayment.paymentType === 'preCheckInSales') {
              setPaymentFlow('paymentSuccess');
            } else {
              setPaymentFlow('depositSuccess');
            }
            if (response.token) {
              setWebPaymentToken(response.token);
            }
          })
          .catch((e: any) => {
            if (e.response?.data?.code !== 'P1') {
              setPaymentErrorCode(e.response?.data?.code);
              setPaymentFlow('paymentError');
              setWebPayment(undefined);
            }
          });
      }
    },
    webPayment !== undefined &&
      chosenPayment !== undefined &&
      isWebPaymentSuccess !== true
      ? 5000
      : null
  );

  let lang = localStorage.getItem('lang');

  const onPaymentMethodClick = async (
    methodCode: string,
    type: string,
    paymentType: 'preCheckInSales' | 'preCheckInDeposit'
  ) => {
    setIsQrPaymentSuccess(undefined);
    setIsWebPaymentSuccess(undefined);
    setPaymentFlow('loadingPayment');
    const postBody: MakePaymentRequestBody = {
      bookingNumber: props.bookingNumber,
      amount:
        paymentType === 'preCheckInSales' && billItems
          ? +billItems
              .reduce(
                (prev: any, current: any) => prev + current.totalAmount,
                0
              )
              .toFixed(2)
          : paymentType === 'preCheckInDeposit' && deposits
          ? +deposits
              .reduce((prev: any, current: any) => prev + current.amount, 0)
              .toFixed(2)
          : 0,
      currency:
        paymentType === 'preCheckInSales' && billItems
          ? billItems[0].currency
          : paymentType === 'preCheckInDeposit' && deposits
          ? deposits[0].currency
          : '',
      paymentType: paymentType,
      paymentMethodCode: methodCode,
      paymentFlowType: type,
      saveToken: methodCode !== 'token' ? !!saveCreditCard : false,
      language: lang === 'en' ? 'en' : lang === 'hk' ? 'tc' : 'sc',
    };
    // console.log({postBody})
    setChosenPayment(postBody);
    try {
      if (type === 'web') {
        const webPayment = await makeWebPayment(postBody);
        setWebPayment(webPayment);
        setPaymentFlow('webPayment');
      } else if (type === 'qrCode') {
        const qrPayment = await makeQrPayment(postBody);
        setQrPayment(qrPayment);
        setPaymentFlow('qrPayment');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onPaymentContinueClick = async () => {
    if (isParkLane === true) {
      if (deposits && deposits.length > 0) {
        getAvailablePaymentMethods(props.bookingNumber).then((response) => {
          const tokenMethod = response.find((x) => x.method.code === 'token');
          if (tokenMethod) {
            setWebPaymentTokenDescription(
              lang === 'en'
                ? tokenMethod.method.textEN
                : lang === 'hk'
                ? tokenMethod.method.textTC
                : lang === 'cn'
                ? tokenMethod.method.textSC
                : tokenMethod.method.textEN
            );
            setPaymentFlow('askUseToken');
          } else {
            setPaymentFlow('askSaveTokenDeposit');
          }
        });
      } else {
        onEndPayment();
      }
    } else {
      localStorage.removeItem('paymentstatus');
      if (depositRequired !== 'skip') {
        setPaymentFlow('depositInit');
      } else {
        onEndPayment();
      }
    }
  };

  const onPaymentErrorClick = async () => {
    localStorage.removeItem('paymentstatus');
    if (billItems && deposits) {
      if (
        billItems.length > 0 &&
        billItems.reduce((prev, x) => prev + x.totalAmount, 0) > 0 &&
        roomRateRequired !== 'skip'
      ) {
        if (isParkLane) {
          setPaymentFlow('askSaveTokenPayment');
        } else {
          setPaymentFlow('paymentInit');
        }
      } else if (depositRequired !== 'skip') {
        if (isParkLane) {
          getAvailablePaymentMethods(props.bookingNumber).then((response) => {
            const tokenMethod = response.find((x) => x.method.code === 'token');
            if (tokenMethod) {
              setWebPaymentTokenDescription(
                lang === 'en'
                  ? tokenMethod.method.textEN
                  : lang === 'hk'
                  ? tokenMethod.method.textTC
                  : lang === 'cn'
                  ? tokenMethod.method.textSC
                  : tokenMethod.method.textEN
              );
              setPaymentFlow('askUseToken');
            } else {
              setPaymentFlow('askSaveTokenDeposit');
            }
          });
        } else {
          setPaymentFlow('depositInit');
        }
      } else {
        onEndPayment();
      }
    }
  };

  const onEndPayment = async () => {
    history.push('confirm');
  };

  const onIframeLoad = (e: any) => {
    e.persist();
  };

  const onPayDepositClick = async () => {
    if (
      webPaymentToken !== undefined &&
      chosenPayment !== undefined &&
      deposits
    ) {
      if (chosenPayment !== undefined)
        try {
          setPaymentFlow('payingDespoitByToken');
          await payPlanetDepositByToken({
            ...chosenPayment,
            paymentType: 'preCheckInDeposit',
            amount: +deposits
              .reduce((prev, x) => prev + x.amount, 0)
              .toFixed(2),
            token: webPaymentToken,
          });
          setPaymentFlow('depositSuccess');
        } catch (e: any) {
          console.log(e);
        }
    } else {
      setPaymentFlow('chooseDepositMethods');
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />

      <Grid
        container
        spacing={2}
        xs={12}
        style={{
          width: '100%',
        }}
      >
        <>
          {isLoadingGetPrearrival && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={'100%'}
            >
              <CircularProgress />
            </Box>
          )}
          {paymentFlow === 'askSaveTokenPayment' && (
            <Grid
              container
              spacing={4}
              style={{ width: '100%', marginLeft: '1.5rem' }}
            >
              <Grid item xs={12}>
                {intl.formatMessage({
                  id:
                    roomRateRequired !== 'compulsory'
                      ? 'choice.roomrateTitleOptional'
                      : 'choice.roomrateTitleCompulsory',
                })}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="choice.roomrate" />{' '}
                {billItems?.[0]?.currency ?? '$'}
                {billItems &&
                  billItems
                    .reduce((prev: number, x: any) => prev + x.totalAmount, 0)
                    .toFixed(2)}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="payment.t&c" />
              </Grid>
              <Grid item sm={1} xs={3} style={{ textAlign: 'center' }}>
                <Checkbox
                  onClick={() => {
                    setSaveCreditCard((prev) => !prev);
                  }}
                />
              </Grid>
              <Grid item sm={11} xs={9} style={{ textAlign: 'left' }}>
                <p style={{ fontSize: '.8rem' }}>
                  {intl.formatMessage({ id: 'choice.askSaveCreditCard' })}
                </p>
              </Grid>
              <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12} sm={12}>
                  {saveCreditCard === false && (
                    <FormHelperText error>
                      {intl.formatMessage({ id: 'choice.warning' })}
                    </FormHelperText>
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnWhite}
                    type="button"
                    onClick={() => {
                      saveCreditCard === true
                        ? onPaymentMethodClick('visa', 'web', 'preCheckInSales')
                        : setSaveCreditCard(false);
                    }}
                  >
                    <FormattedMessage id="choice.yes" />
                  </Button>
                </Grid>
                {roomRateRequired !== 'compulsory' ? (
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.btnWhite}
                      type="button"
                      onClick={() => onPaymentContinueClick()}
                    >
                      <FormattedMessage id="choice.roomrate.no" />
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}

          {paymentFlow === 'askSaveTokenDeposit' && (
            <Grid
              container
              spacing={4}
              style={{ width: '100%', marginLeft: '1.5rem' }}
            >
              <Grid item xs={12}>
                {intl.formatMessage({
                  id:
                    depositRequired !== 'compulsory'
                      ? 'choice.depositTitleOptional'
                      : 'choice.depositTitleCompulsory',
                })}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="choice.deposit" /> $
                {deposits &&
                  deposits
                    .reduce((prev: number, x: any) => prev + x.amount, 0)
                    .toFixed(2)}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="payment.t&c" />
              </Grid>
              <Grid item sm={1} xs={3} style={{ textAlign: 'center' }}>
                <Checkbox
                  onClick={() => {
                    setSaveCreditCard((prev) => !prev);
                  }}
                />
              </Grid>
              <Grid item sm={11} xs={9} style={{ textAlign: 'left' }}>
                <p style={{ fontSize: '.8rem' }}>
                  {intl.formatMessage({ id: 'choice.askSaveCreditCard' })}
                </p>
              </Grid>
              <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12} sm={12}>
                  {saveCreditCard === false && (
                    <FormHelperText error>
                      {intl.formatMessage({ id: 'choice.warning' })}
                    </FormHelperText>
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnWhite}
                    type="button"
                    onClick={() => {
                      saveCreditCard === true
                        ? onPaymentMethodClick(
                            'visa',
                            'web',
                            'preCheckInDeposit'
                          )
                        : setSaveCreditCard(false);
                    }}
                  >
                    <FormattedMessage id="choice.yes" />
                  </Button>
                </Grid>
                {depositRequired !== 'compulsory' ? (
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.btnWhite}
                      type="button"
                      onClick={() => onEndPayment()}
                    >
                      <FormattedMessage id="choice.no" />
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}

          {paymentFlow === 'paymentInit' && roomRateRequired && (
            <>
              {billItems && billItems.length > 0 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: '#808080',
                      marginTop: 40,
                      marginBottom: 20,
                      textAlign: 'center',
                      marginLeft: '1.5rem',
                    }}
                  >
                    {intl.formatMessage({
                      id:
                        roomRateRequired !== 'compulsory'
                          ? 'choice.roomrateTitleOptional'
                          : 'choice.roomrateTitleCompulsory',
                    })}
                    <br />
                    <br />
                    <FormattedMessage id="choice.total" />：
                    {billItems?.[0]?.currency ?? '$'}
                    {billItems
                      ?.reduce(
                        (prev: any, current: any) => prev + current.totalAmount,
                        0
                      )
                      .toFixed(2)}
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <FormattedMessage id="payment.t&c" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {intl.formatMessage({
                      id: 'checkinpayment.selectpaymentmethod',
                    })}
                  </Grid>

                  <Grid
                    item
                    container
                    spacing={2}
                    style={{ justifyContent: 'center' }}
                  >
                    {paymentMethods
                      .filter(
                        (payment: any) =>
                          payment.type === 'qrCode' ||
                          (payment.type === 'web' &&
                            payment.method.code !== 'token')
                      )
                      .map((x, i) => (
                        <Grid item sm={3} key={i}>
                          <Button
                            fullWidth
                            variant="contained"
                            type="button"
                            onClick={() =>
                              onPaymentMethodClick(
                                x.method.code,
                                x.type,
                                'preCheckInSales'
                              )
                            }
                            style={{ background: 'transparent' }}
                          >
                            <img
                              width={'100%'}
                              src={`data:image/png;base64,${x.method.logo}`}
                              alt={x.method.textEN}
                            />
                          </Button>
                        </Grid>
                      ))}
                  </Grid>
                  {roomRateRequired !== 'compulsory' ? (
                    <Grid item xs={12} sm={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.btnWhite}
                        type="button"
                        onClick={() => onPaymentContinueClick()}
                      >
                        <FormattedMessage id="choice.roomrate.no" />
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>No billItem</>
              )}
            </>
          )}

          {(paymentFlow === 'loadingPayment' ||
            paymentFlow === 'payingDespoitByToken') && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '1rem',
              }}
            >
              <CircularProgress />
            </Grid>
          )}

          {paymentFlow === 'webPayment' &&
            webPayment !== undefined &&
            paymentVendor !== 'asiaPay' &&
            paymentVendor !== 'dbs' && (
              <div className={classes.webPaymentWrapper}>
                <iframe
                  src={webPayment.link}
                  title="Web payment"
                  onLoad={(e) => onIframeLoad(e)}
                  style={{
                    width: '100%',
                    height: '100vh',
                  }}
                />
              </div>
            )}

          {paymentFlow === 'webPayment' &&
            webPayment !== undefined &&
            paymentVendor === 'dbs' && (
              <div>
                {webPayment !== undefined &&
                  chosenPayment !== undefined &&
                  isWebPaymentSuccess !== true && (
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      Checking payment status
                      <CircularProgress />
                    </div>
                  )}
                <div>
                  {intl.formatMessage({ id: 'checkinpayment.payInNewTab' })}
                </div>
                <div>
                  {intl.formatMessage({
                    id: 'checkinpayment.payInNewTabNotOpened',
                  })}
                </div>
                <button onClick={() => window.open(webPayment.link, '_blank')}>
                  {intl.formatMessage({ id: 'checkinpayment.startPayment' })}
                </button>
              </div>
            )}

          {paymentFlow === 'qrPayment' && qrPayment !== undefined && (
            <>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {intl.formatMessage({ id: 'checkinpayment.pleasescanqrcode' })}
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={qrPayment.qrcode} alt="payment qr code" />
              </Grid>
            </>
          )}
          {(paymentFlow === 'paymentSuccess' ||
            paymentFlow === 'depositSuccess') && (
            <Grid
              container
              spacing={4}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: '1rem',
              }}
            >
              <Grid item>
                {intl.formatMessage({ id: 'checkinpayment.success' })}
              </Grid>
              <Grid item>
                {intl.formatMessage({ id: 'checkinpayment.merchantRef' }) +
                  webPayment?.merchantRef}
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btnWhite}
                  type="button"
                  onClick={() =>
                    paymentFlow === 'paymentSuccess'
                      ? onPaymentContinueClick()
                      : onEndPayment()
                  }
                >
                  {intl.formatMessage({ id: 'checkinpayment.continue' })}
                </Button>
              </Grid>
            </Grid>
          )}

          {paymentFlow === 'paymentError' && (
            <Grid
              container
              spacing={4}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid item>
                {paymentErrorCode === 'P3'
                  ? intl.formatMessage({ id: 'checkinpayment.bankError' })
                  : intl.formatMessage({ id: 'checkinpayment.error' })}
              </Grid>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnWhite}
                type="button"
                onClick={() => onPaymentErrorClick()}
              >
                {intl.formatMessage({ id: 'checkinpayment.continue' })}
              </Button>
            </Grid>
          )}

          {paymentFlow === 'depositInit' && deposits && depositRequired && (
            <Grid
              container
              spacing={4}
              style={{ width: '100%', marginLeft: '1.5rem' }}
            >
              <Grid item xs={12}>
                {intl.formatMessage({
                  id:
                    depositRequired !== 'compulsory'
                      ? 'choice.depositTitleOptional'
                      : 'choice.depositTitleCompulsory',
                })}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="choice.deposit" />{' '}
                {deposits?.[0]?.currency ?? '$'}
                {deposits.reduce((prev, x) => prev + x.amount, 0).toFixed(2)}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="payment.t&c" />
              </Grid>
              <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.btnWhite}
                    //color="primary"
                    //type="submit"
                    type="button"
                    onClick={() => onPayDepositClick()}
                  >
                    <FormattedMessage id="choice.yes" />
                  </Button>
                </Grid>
                {depositRequired !== 'compulsory' ? (
                  <Grid item xs={12} sm={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.btnWhite}
                      type="button"
                      onClick={() => onEndPayment()}
                    >
                      <FormattedMessage id="choice.no" />
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}

          {paymentFlow === 'askUseToken' && deposits && (
            <>
              <Grid item xs={12}>
                {intl.formatMessage({
                  id:
                    depositRequired !== 'compulsory'
                      ? 'choice.depositTitleOptional'
                      : 'choice.depositTitleCompulsory',
                })}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="choice.deposit" /> $
                {deposits.reduce((prev, x) => prev + x.amount, 0).toFixed(2)}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormattedMessage id="payment.t&c" />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'center',
                }}
              >
                {intl.formatMessage({
                  id: 'checkinpayment.useSavedCard',
                })}
              </Grid>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnWhite}
                type="button"
                onClick={async () => {
                  await onPaymentMethodClick(
                    'token',
                    'web',
                    'preCheckInDeposit'
                  );
                }}
              >
                {webPaymentTokenDescription}
              </Button>
            </>
          )}

          {paymentFlow === 'chooseDepositMethods' && (
            <>
              <Grid
                item
                container
                spacing={2}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {intl.formatMessage({
                    id: 'checkinpayment.selectpaymentmethod',
                  })}
                </Grid>
                {paymentMethods
                  .filter((x) => x.payType.includes('deposit'))
                  .map((x, i) => (
                    <Grid
                      item
                      sm={3}
                      key={i}
                      style={{ justifyContent: 'center' }}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        type="button"
                        onClick={() =>
                          onPaymentMethodClick(
                            x.method.code,
                            x.type,
                            'preCheckInDeposit'
                          )
                        }
                        style={{ background: 'transparent' }}
                      >
                        <img
                          width={'100%'}
                          src={`data:image/png;base64,${x.method.logo}`}
                          alt={x.method.textEN}
                        />
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </>
      </Grid>
    </Container>
  );
};
