import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { wrapper } from 'theme';
import { Navigation } from 'modules/navigation';
//import { LanguageSwitcher } from 'modules/i18n';
import { padding } from 'theme/padding';
import { Primary } from '../../../theme/colors';
import { getHotelTheme } from 'api';

export const AppLayout: React.FC = ({ children }) => {
  const [navColor, setNavColor] = useState('black');

  const styles = StyleSheet.create({
    container: {
      flex: '1 1 auto',
      flexDirection: 'column',
      fontFamily: "'Open Sans', sans-serif",
    },
    header: {
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: navColor,
      borderBottom: '1px solid #f6f6f6',
      padding: '1em 0',
    },
    nav: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    main: {
      margin: '0 auto',
    },
  });

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      getHotelTheme().then((response) => {
        console.log(response);
        if (response.palette?.primary.main) {
          setNavColor(response.palette?.primary.main);
        }
      });
    }
  }, [localStorage.getItem('token')]);

  return (
    <div className={css(styles.container)}>
      <header
        className={css(styles.header)}
        style={{ backgroundColor: navColor }}
      >
        <nav className={css(styles.nav, wrapper[95])}>
          <Navigation />
          {/* <LanguageSwitcher /> */}
        </nav>

        <link
          href="//netdna.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
          rel="stylesheet"
        />
      </header>
      <main className={css(styles.main, wrapper[90], padding.medium)}>
        {children}
      </main>
    </div>
  );
};
