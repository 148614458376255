import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Primary, Secondary } from '../theme/colors';
import dayjs from 'dayjs';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { roomTypeArray } from 'const/roomTypes';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    marginRight: theme.spacing(15),
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
}));

export const Review = (props: {
  hotelId: string;
  data: any;
  errMsgObj: any;
}) => {
  // localStorage.clear();
  const isParkLane = props?.hotelId === '6311619228fa6745ccf22e56';
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [showErr, setShowErr] = useState(false);
  // console.log("hotelname:", hotel.hotelName)
  const [fillHdf, setFillHdf] = useState(isParkLane);

  useEffect(() => {
    // console.log(props);
    if (
      props.data.confirmationNumber === '' ||
      props.data.confirmationNumber === null ||
      props.data.confirmationNumber === 'undefined'
    ) {
      setShowErr(true);
    } else {
      setShowErr(false);
    }
  }, [props]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Formik
        initialValues={props.data}
        onSubmit={(values) => {
          values.confirmationNumber = props.data.confirmationNumber;
          localStorage.setItem('profileId', props.data.profileId);
          localStorage.setItem('firstName', props.data.firstName);
          localStorage.setItem('lastName', props.data.lastName);
          localStorage.setItem(
            'confirmationNumber',
            props.data.confirmationNumber
          );
          localStorage.setItem('address1', props.data.address1);
          localStorage.setItem('address2', props.data.address2);
          localStorage.setItem('country', props.data.country);
          localStorage.setItem('zipCode', props.data.zipCode);
          localStorage.setItem('tel', props.data.tel);
          localStorage.setItem('email', props.data.email);
          localStorage.setItem('passportNumber', props.data.passportNumber);
          props.data.dateOfBirth &&
            localStorage.setItem('dateOfBirth', props.data.dateOfBirth);
          history.push('registration');
        }}
      >
        {() => (
          <Form className={classes.form} noValidate>
            <div className={showErr ? classes.show : classes.hide}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    color: '#808080',
                    fontSize: '1.2rem',
                    marginTop: 40,
                    marginBottom: 40,
                    textAlign: 'center',
                  }}
                >
                  {/* <FormattedMessage id="error.message.aleadySubmit" /> */}
                  {props.errMsgObj.errCode !== ''
                    ? props.errMsgObj.errCode === '404' ||
                      props.errMsgObj.errCode === 'H404'
                      ? intl.formatMessage({ id: 'error.message.notFound' })
                      : props.errMsgObj.errMsg === 'already submitted'
                      ? intl.formatMessage({ id: 'error.message.aleadySubmit' })
                      : intl.formatMessage({ id: 'error.message.urlInvalid' })
                    : intl.formatMessage({ id: 'loading.content' })}
                </Grid>
              </Grid>
            </div>
            <div
              className={showErr ? classes.hide : classes.show}
              style={{ marginLeft: '1rem' }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    color: '#808080',
                    fontSize: '1.2rem',
                    marginTop: 40,
                    marginBottom: 40,
                  }}
                >
                  <FormattedMessage id="review.title" />
                </Grid>
                <Grid item xs={6} style={{ color: '#808080' }}>
                  <FormattedMessage id="review.confirm.no" />
                </Grid>
                <Grid item xs={6}>
                  {props.data.confirmationNumber}
                </Grid>
                <Grid item xs={6} style={{ color: '#808080' }}>
                  <FormattedMessage id="review.guest.name" />
                </Grid>
                <Grid item xs={6}>
                  {props.data.lastName} {props.data.firstName}
                </Grid>
                <Grid item xs={6} style={{ color: '#808080' }}>
                  <FormattedMessage id="review.checkin.date" />
                </Grid>
                <Grid item xs={6}>
                  {props.data.checkInDate && props.data.checkOutDate
                    ? (dayjs(props.data.checkInDate, 'YYYYMMDDHHmm').isValid()
                        ? dayjs(props.data.checkInDate, 'YYYYMMDDHHmm').format(
                            'YYYY-MM-DD'
                          )
                        : props.data.checkInDate) +
                      ' - ' +
                      (dayjs(props.data.checkOutDate, 'YYYYMMDDHHmm').isValid()
                        ? dayjs(props.data.checkOutDate, 'YYYYMMDDHHmm').format(
                            'YYYY-MM-DD'
                          )
                        : props.data.checkOutDate)
                    : ''}
                </Grid>
                <Grid item xs={6} style={{ color: '#808080' }}>
                  <FormattedMessage id="review.reserved.room.type" />
                </Grid>
                <Grid item xs={6}>
                  {roomTypeArray.find(
                    (element) => element === props.data.roomToChargeTypeCode
                  )
                    ? intl.formatMessage({
                        id:
                          'review.room.type.' +
                          props.data?.roomToChargeTypeCode,
                      })
                    : props.data.roomToChargeTypeDescription}
                </Grid>
                {isParkLane === true && props.data.billItems.length > 0 && (
                  <>
                    <Grid item xs={6} style={{ color: '#808080' }}>
                      <FormattedMessage id="review.total.room.rate" />
                    </Grid>
                    <Grid item xs={6}>
                      HKD
                      {props.data.billItems
                        .reduce(
                          (prev: any, current: any) =>
                            prev + current.totalAmount,
                          0
                        )
                        .toFixed(2)}
                    </Grid>
                  </>
                )}
                {/* <Grid item xs={6} style={{ color: "#808080" }} >
                  <FormattedMessage id="review.preferences" />
                </Grid>
                <Grid item xs={6} >
                  {data.preferences}
                </Grid> */}

                {/*
                {!isParkLane ? (
                  <></>
                ) : (
                  <Grid item xs={12}>
                    <div
                      style={{
                        margin: 0,
                        position: 'relative',
                        top: '50%',
                        left: '20%',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fillHdf}
                            onChange={(e) => {
                              setFillHdf(!fillHdf);
                            }}
                            name="symptom"
                            color="primary"
                          />
                        }
                        label={<FormattedMessage id="hdf.ask" />}
                        labelPlacement="end"
                      />
                    </div>
                  </Grid>
                )}
                */}
                <Grid item xs={12}>
                  <div
                    style={{
                      margin: 0,
                      position: 'relative',
                      top: '50%',
                      left: '20%',
                    }}
                  >
                    <Button
                      style={{
                        width: '50%',
                        background: Primary,
                        color: Secondary,
                      }}
                      variant="contained"
                      type="submit"
                    >
                      <FormattedMessage id="btn.confirm" />
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ color: '#808080' }}>
                &nbsp;
              </Grid>
              <Grid item xs={12} style={{ color: '#808080' }}>
                &nbsp;
              </Grid>
              {/* <Grid item xs={12} style={{ color: "#808080" }} >
                <FormattedMessage id="review.note" />
              </Grid> */}
              <Grid item xs={12} style={{ color: '#808080' }}>
                &nbsp;
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
