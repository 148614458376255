import axios from 'axios';
import { AppLanguage, AppRoute } from 'const';
import dayjs from 'dayjs';
import { appStrings, LocalizedRouter, LocalizedSwitch } from 'modules/i18n';
import { AppLayout } from 'modules/layout';
import 'normalize.css';
import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import 'typeface-open-sans';
import {
  CheckInPayment,
  Confirm,
  ConfirmPayment,
  GeneralError,
  HdfLayout,
  Registration,
  Review,
} from 'views';
import { getHotelConfigById, getPrearrivalBooking } from './api';

localStorage.clear();
const qsObject: any = qs.parse(window.location.search);
const langPath =
  window.location.pathname
    .split('/')
    .find((item) => ['en', 'hk', 'cn'].includes(item)) || 'en';

const langQuery = qsObject.lang;
const lang =
  langQuery !== undefined && ['en', 'hk', 'cn'].includes(langQuery)
    ? langQuery
    : langPath;
localStorage.setItem('lang', lang);
localStorage.setItem('ref_no', qsObject.refno);
localStorage.setItem('price', qsObject.price);
localStorage.setItem('skip', qsObject.skip);
localStorage.setItem('token', qsObject.token);
localStorage.setItem('paymentstatus', qsObject.paymentstatus);

function parseJwt(jwt: any) {
  if (!jwt) return {};
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
const jwtPayload = parseJwt(qsObject.token);
const { hotelId, id } = jwtPayload;

localStorage.setItem('id', qsObject.id || id);

if (qsObject.st) {
  localStorage.setItem('status', qsObject.st);
} else {
  localStorage.removeItem('status');
}

const getInitialValues = async (
  setToken: (props: string) => void,
  setData: (props: any) => void,
  setErrMsgObj: (props: any) => void
) => {
  const id = localStorage.getItem('id');
  if (
    qsObject.token !== undefined &&
    qsObject.token !== '' &&
    qsObject.token !== 'undefined'
  ) {
    setToken(qsObject.token);
    if (!(id === null || id === '' || id === 'undefined')) {
      try {
        const data = await getPrearrivalBooking(id);
        setData({
          ...data,
          profileId: data.profile.profileId,
          confirmationNumber: data.confirmationNumber,
          firstName: data.profile.firstName,
          lastName: data.profile.lastName,
          address1: data.profile.address.address1 ?? '',
          address2: data.profile.address.address2 ?? '',
          country: data.profile.address.countryCode,
          zipCode: data.profile.address.zipCode ?? '',
          email: data.profile.email.email,
          tel: data.profile.phoneNumber.number,
          passportNumber: data.profile.document.documentNumber,
          dateOfBirth: data.profile.dateOfBirth,
          checkInDate: dayjs(data.checkInDate).format('YYYY-MM-DD'),
          checkOutDate: dayjs(data.checkOutDate).format('YYYY-MM-DD'),
          roomTypeCode: data.roomTypeCode,
          billItems: data.billItems,
          deposits: data.deposits,
          isDepositPaid: data.isDepositPaid,
          accompanyGuests: data.accompanyGuests,
        });

        localStorage.bookingJson = JSON.stringify(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          console.log(err.message);
          setErrMsgObj({
            errCode: err.code,
            errMsg: err.message,
          });
        }
      }
    } else {
      setErrMsgObj({
        errCode: '400',
        errMsg: 'Pre arrival id needed',
      });
    }
  } else {
    setErrMsgObj({
      errCode: '400',
      errMsg: 'Token needed',
    });
  }
};

export const App = () => {
  const id = localStorage.getItem('id') ?? '';
  const paymentstatus = localStorage.getItem('paymentstatus') ?? undefined;
  const [data, setData] = useState({
    confirmationNumber: '',
    firstName: '',
    lastName: '',
    checkInDate: '',
    checkOutDate: '',
    roomTypeCode: '',
    profileId: '',
    address1: '',
    address2: '',
    country: '',
    zipCode: '',
    email: '',
    tel: '',
    passportNumber: '',
    dateOfBirth: '',
    billItems: [],
    deposits: [],
    isDepositPaid: false,
    accompanyGuests: [],
  });
  const [errMsgObj, setErrMsgObj] = useState({
    errCode: '',
    errMsg: '',
  });
  const [token, setToken] = useState('');
  const [hotelConfig, setHotelConfig] = useState();

  const getHotelInfo = async (jwt: string) => {
    const hotelConfig = await getHotelConfigById(jwt);
    console.log(hotelConfig);
    setHotelConfig({ ...hotelConfig });
  };

  useEffect(() => {
    getInitialValues(setToken, setData, setErrMsgObj);
    getHotelInfo(qsObject.token);
  }, []);

  return (
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      languages={AppLanguage}
      appStrings={appStrings}
    >
      <AppLayout>
        <LocalizedSwitch>
          <Route exact path={AppRoute.review}>
            <Review hotelId={hotelId} data={data} errMsgObj={errMsgObj} />
          </Route>
          <Route exact path={AppRoute.registration}>
            <Registration token={token} hotelId={hotelId} />
          </Route>
          <Route exact path={AppRoute.hdfForm}>
            <HdfLayout data={data} setData={setData} token={token} />
          </Route>
          <Route exact path={AppRoute.confirm}>
            <Confirm token={token} hotelId={hotelId} />
          </Route>
          <Route exact path={AppRoute.checkinpayment}>
            <CheckInPayment
              hotelId={hotelId}
              hotelConfig={hotelConfig}
              id={id}
              bookingNumber={data.confirmationNumber}
              status={
                paymentstatus as
                  | 'paymentSuccess'
                  | 'depositSuccess'
                  | 'paymentError'
                  | 'undefined'
              }
            />
          </Route>
          <Route exact path={AppRoute.confirmpayment}>
            <ConfirmPayment
              hotelId={hotelId}
              hotelConfig={hotelConfig}
              data={data}
              errMsgObj={errMsgObj}
            />
          </Route>
          <Route path="*">
            <GeneralError />
          </Route>
        </LocalizedSwitch>
      </AppLayout>
    </LocalizedRouter>
  );
};
